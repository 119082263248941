.intro{
   display: flex;
   height: 55vh;
   margin-top: 6rem;
    /* background-image: url('https://static.vecteezy.com/system/resources/previews/001/849/553/original/modern-gold-background-free-vector.jpg');   */

}

.i-left{
    display: flex;
    position: relative;
    flex: 1;
    flex-direction: column;
    gap: 2rem;
}

.i-name{
    display: flex;
    flex-direction: column;
}
.i-name>:nth-child(1){
    color: var(--black);
    font-weight: bold;
    font-size: 3rem;
}
.i-name>:nth-child(2){
    color: var(--orange);
    font-weight: bold;
    font-size: 3rem;
}
.i-name>:nth-child(3){
    color: var(--black);
    font-weight: 100;
    font-size: 14px;
    margin-top: 10px;
}

.i-button{
    width: 6rem;
    height: 2rem;
}

.i-right{
flex: 1;
position: relative;
}

.i-right>*{
    position: absolute;
    z-index: 1;
}
.i-right>:nth-child(1){
    transform: scale(0.69);
    left: 4rem;
    top: -6rem;
}

 .i-right>:nth-child(2){
    transform: scale(1.1);
    left: 3rem;
    top: -3rem;
} 

@media screen and (max-width:480px){
     .intro{
        flex-direction: column;
        margin-top: 3rem;
    }
    .i-right{
       /* transform: scale(0.8); 
       left:-3rem */
       transform: scale(0.9);
       left: -4rem;
       top: 3rem;

    }
    .i-right .blur{
        display: none;
    }

 .i-right>:nth-child(1){
        transform: scale(0.69);
        left: 1rem;
        top: -5.5rem;
    }
    
     .i-right>:nth-child(2){
        transform: scale(1.1);
        left: 2rem;
        top: -3rem;
    }  
            
}