.services{
    /* padding: 0 3rem 0 3rem; */
    display: flex;
    height: 90vh;
    margin-bottom: 8rem;
    margin-top: 5rem;
    margin-bottom: 10rem;

}

.awesome{
   display: flex;
   flex-direction: column;
   position: relative; 
}

.awesome > :nth-child(1) {
    color: var(--black);
    font-weight: bold;
    font-size: 2.5rem;
}
.awesome > :nth-child(2) {
    color: var(--orange);
    font-weight: bold;
    font-size: 2.5rem;
}
/* .awesome > :nth-child(3) {
    color: var(--black);
    margin-top: 1rem;
    font-size: 14px;
     
} */
.s-button{
    width: 8rem;
    height: 2rem;
    margin-top: 1rem;
}
.cards{
    position: relative;
}

.cards>*{
    position: absolute;
}

@media screen and (max-width:480px){
    .services{
        margin-top: 20rem;
        flex-direction: column;
        gap:5rem;
        height: 62rem;
        padding: 0;
    }     
    .cards{
        display: flex;
        flex-direction: column;
        gap:18rem;
    }
    .cards>*{
        position: static;
    }
    .awesome > :nth-child(3) {
        color: var(--black);
        margin-top: 1rem;
        font-size: 14px;
    }
    
    }