 .experience {
   display: flex;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  height: 20vh;
  margin-bottom: 5rem; 
  
}
.experience.heading {
  /* display: flex; */
  /* align-items: left; */
  /* justify-content: center; */
  /* flex-direction: column; */
  height: 60vh;

}
/* heading */
.heading span:nth-of-type(1) {
  color: #fca61f;
  font-size: 2rem;
  font-weight: bold;
}

.heading span:nth-of-type(2) {
  color: var(--orange);
  font-size: 2.5rem;
  font-weight: bold;
} 


  
.achievement {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.circle {
  width: 7rem;
  height: 7rem;
  background: white;
  border-radius: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
  background-image: url('../../imgs/photo.jpg');
   
}
.circle::before {
  border-radius: 100%;
  content: "";
  background-image: url('../../imgs/photo.jpg');
  
  top: -8px;
  left: -8px;
  bottom: -8px;
  right: -8px;
  position: absolute;
  z-index: -1;
  box-shadow: var(--boxShadow);
}

.achievement span:nth-of-type(2) {
  color: #009688;
}


@media screen and (max-width:480px){
   .experience{
    transform: scale(0.58); 
    height: 37vh;
    margin-bottom: 1rem;
    top:1rem;
  } 
   
  }
   




 